import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import router from './router';
import process from 'process';
console.log('入口文件',process);
window.process = process;
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 的样式

Vue.use(ElementUI);
Vue.use(VueRouter);  
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
