<template>
  <el-drawer
    title="商品评论(7642)"
    :visible="commentDrawer"
    direction="btt"
    size="70%"
    :before-close="handleClose"
  >
    <div class="observer" ref="scrollContainer">
      <ul>
        <template v-if="observerIndex > 0">
          <li>
            <img
              src="@/assets/boserverimg/wise.1.1d10db74.Sh9Bku95V7CzwayhxS3xIQ.jpg"
              alt=""
            />
            <div>
              <h4>斑马疯人da9e9e951</h4>
              <h5>
                安全性是我给这个账号最高的评价，交易过程非常安全，没有任何风险。
              </h5>
              <div><span>6分钟前 上海</span> <strong>推特新号</strong></div>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.2a74ee08.DYmnkc8QgVkvdWTa3d9jyg.jpg"
              alt=""
            />
            <div>
              <h4>152****7931</h4>
              <h5>发货速度很快，客服小姐姐也很有耐心，点赞</h5>
              <span>28分钟前 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.3ff1bbc2.ckhkgDtNABwbmGzW-PWR3Q.jpg"
              alt=""
            />
            <div>
              <h4>狼人1549adf</h4>
              <h5>第三次购买，还是一如既往的靠谱，极力推荐。。</h5>
              <span>1小时前 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.f785bc1e.r9FpGoLffBB5paPPMI8n3Q.jpg"
              alt=""
            />
            <div>
              <h4>雷豹DFA</h4>
              <h5>很棒这个产品</h5>
              <span>1小时前 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.120e6225.cSHVUuF7RRU08rWAsNDWNg.jpg"
              alt=""
            />
            <div>
              <h4>斯蒂芬的家乡</h4>
              <h5>付完款就收到账号了，秒发货，五星好评。</h5>
              <span>1小时前 上海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.88de96eb.3oe4nSC_sX39rByd7sjbLw.jpg"
              alt=""
            />
            <div>
              <h4>182****7411</h4>
              <h5>嘎嘎靠谱</h5>
              <span>2小时前 西安</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.18adc4fe.auz8F6mRIeL7GrYBHJK-fQ.jpg"
              alt=""
            />
            <div>
              <h4>狗熊1666</h4>
              <h5>卖家回应很迅速,给力。号没什么问题,正在使用中。</h5>
              <span>2小时前 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.f785bc1e.r9FpGoLffBB5paPPMI8n3Q.jpg"
              alt=""
            />
            <div>
              <h4>德玛西亚</h4>
              <h5>这次购物体验超出了我的期望值，发货速度非常快</h5>
              <span>小时前 厦门</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.120e6225.cSHVUuF7RRU08rWAsNDWNg.jpg"
              alt=""
            />
            <div>
              <h4>斑马疯人da9e9e951</h4>
              <h5>这是一次非常满意购物体验‌。</h5>
              <span>2分钟前 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.9a76511a.CndzX5aPIWr3d4X0CydBzQ.jpg"
              alt=""
            />
            <div>
              <h4>188****1473</h4>
              <h5>账号靠谱，速度也快。好评。。</h5>
              <span>3小时前 北京</span> <strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 1">
          <li>
            <img
              src="@/assets/boserverimg/01b0e560fc345f11013eaf70d3d257.jpg"
              alt=""
            />
            <div>
              <h4>188****1473</h4>
              <h5>
                这个账号的价格非常合理，相比其他平台便宜很多，而且使用体验非常好。
              </h5>
              <span> {{ computedTime(4) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/001SHyoKgy1hqx9nbfv01j60rs0rsdgw02.jpg"
              alt=""
            />
            <div>
              <h4>192****6584</h4>
              <h5>卖家非常友好，解答了我所有的疑问，并提供了额外的帮助</h5>
              <span>{{ computedTime(4.2) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/006M8I3Hgy1h884nyr3tlj312e12en4l.jpg"
              alt=""
            />
            <div>
              <h4>尘夏幕</h4>
              <h5>
                购买过程安全无忧，卖家提供了详细的操作说明，让我在使用时感到非常安心
              </h5>
              <span>{{ computedTime(4.3) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="" alt="" />
            <div>
              <h4>莈冇伱のㄖ孒</h4>
              <h5>提供的账号信息完全符合描述，没有任何问题。</h5>
              <span>{{ computedTime(4.5) }} 广州</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/006nmfX9gy1hs3z3wa98mj30ec0ec74x.jpg"
              alt=""
            />
            <div>
              <h4>法师995</h4>
              <h5>刚刚购买了这个账号，整体体验非常棒！</h5>
              <span>{{ computedTime(4.5) }} 湛江</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/008vBkYfly1hs9my0hazpj30rs0rsjt1.jpg"
              alt=""
            />
            <div>
              <h4>131****4971</h4>
              <h5>终于找到你，还好我没放弃。</h5>
              <span>{{ computedTime(5) }} 郑州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/wise.1.120e6225.cSHVUuF7RRU08rWAsNDWNg.jpg"
              alt=""
            />
            <div>
              <h4>斑马疯人1451</h4>
              <h5>交易过程非常安全，没有任何风险。</h5>
              <span>{{ computedTime(6) }} 开封</span>  <strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="" alt="" />
            <div>
              <h4>152****7931</h4>
              <h5>抱着试试的心态购买，没想到这么靠谱，好评。</h5>
              <span>{{ computedTime(16) }} 广州</span>  <strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/u=3275202539,427782973&fm=3039&app=3039&f=JPEG.jpg"
              alt=""
            />
            <div>
              <h4>族人sdf</h4>
              <h5>好评。。。。。。</h5>
              <span>{{ computedTime(20) }} 南京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/008vBkYfly1hs9my0iql2j30rs0rs0tt.jpg"
              alt=""
            />
            <div>
              <h4>154****6985</h4>
              <h5>客服小姐姐很温柔，很有耐心。</h5>
              <span>{{ computedTime(20.2) }} 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 2">
          <li>
            <img
              src="@/assets/boserverimg/0033ImPzly1hs9iu7gkslj61o01o0gr302.jpg"
              alt=""
            />
            <div>
              <h4>龙总</h4>
              <h5>优质产品，优质产品，优质产品，重要的事情说三遍。</h5>
              <span> {{ computedTime(25) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/0064zot3gy1hqgtiddthmj60zu0z3tgt02.jpg"
              alt=""
            />
            <div>
              <h4>181****6654</h4>
              <h5>OK以上...</h5>
              <span>{{ computedTime(27.5) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/0074P3wTgy1h7jn495l1qj30u00towjz.jpg"
              alt=""
            />
            <div>
              <h4>frimary</h4>
              <h5>还行吧，客服半天不回应，不过最后还是解决了问题。</h5>
              <span>{{ computedTime(28.3) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/634b1b57gy1hrtmlu22zzj20u00u075n.jpg"
              alt=""
            />
            <div>
              <h4>用户168</h4>
              <h5>空有账号，vpn 不好用</h5>
              <span>{{ computedTime(32) }} 佛山</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/634b1b57gy1hrtmlvba4gj20u00u0ac3.jpg"
              alt=""
            />
            <div>
              <h4>131****9741</h4>
              <h5>账号很靠谱，五星好评</h5>
              <span>{{ computedTime(41.1) }} 茂名</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/00683qmbgy1hr7hxdi61lj30u00u0dgo.jpg"
              alt=""
            />
            <div>
              <h4>141****2657</h4>
              <h5>。。。。</h5>
              <span>{{ computedTime(46.1) }} 柳州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/90440ed7gy1hs86v9i3czj20u00u0gml.jpg"
              alt=""
            />
            <div>
              <h4>早**糖</h4>
              <h5>
                迟来的好评。
                店家很是细心，有什么问题及时回复。也很有耐心的解答。体验感很好。哈哈哈!会推荐给其他人，很是划算。
              </h5>
              <span>{{ computedTime(47) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/71917129ly1hqpz8f7ohej21kw1kwalj.jpg"
              alt=""
            />
            <div>
              <h4>倾**纸</h4>
              <h5>
                体验感非常好，特别满意，很多次回购了，推荐大家找他买～售后非常到位
              </h5>
              <span>{{ computedTime(47) }} 佛山</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="" alt="" />
            <div>
              <h4>z**z</h4>
              <h5>
                终于登录上了 注册了半天 网卡绕圈圈 还不如直接买一个账号方便
              </h5>
              <span>{{ computedTime(48.4) }} 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/boserverimg/20200318113041_cbquf.jpg" alt="" />
            <div>
              <h4>t**8</h4>
              <h5>
                终于登上了…自己琢磨了两天都没搞好
                朋友推荐来买个账号，一下子搞定。
              </h5>
              <span>{{ computedTime(49.5) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 3">
          <li>
            <img
              src="@/assets/boserverimg/b47125f3gy1hqw02ai2saj20r80r847z.jpg"
              alt=""
            />
            <div>
              <h4>第**吖</h4>
              <h5>服务也很好 还有各种各样的说明 简直了👍👍👍</h5>
              <span>{{ computedTime(52) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/b47125f3gy1hqw027565ej20to0tg4lh.jpg"
              alt=""
            />
            <div>
              <h4>偶**啊</h4>
              <h5>
                初始邮箱的权利是最大的，找回很容易，但是除了找回，其他都不怕。
                总的来说还是不错的
              </h5>
              <span>{{ computedTime(52.9) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="" alt="" />
            <div>
              <h4>188****3241</h4>
              <h5>
                客服态度非常好有售后保障有更多小礼品小礼物第二次回购很不错.
              </h5>
              <span>{{ computedTime(60) }} 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/boserverimg/b47125f3gy1hqw028427vj20u00t8tw8.jpg"
              alt=""
            />
            <div>
              <h4>t**5</h4>
              <h5>终于登上了，之前搞了很长时间，不知道还能购买账号。</h5>
              <span>{{ computedTime(61.2) }} 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/u=1604801561,161132950&fm=253&fmt=auto&app=138&f=JPEG.webp"
              alt=""
            />
            <div>
              <h4>斑马疯人da9e9e951</h4>
              <h5>
                安全性是我给这个账号最高的评价，交易过程非常安全，没有任何风险。
              </h5>
              <span>{{ computedTime(63) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/0ff254910d90afc64b2ae70f9699bcb5.jpeg"
              alt=""
            />
            <div>
              <h4>h**7</h4>
              <h5>客服非常耐心的解答问题，发货速度非常快，值得回购</h5>
              <span>{{ computedTime(73) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/img2/3ea400098a0c8ed06c0e.jpg" alt="" />
            <div>
              <h4>t**0</h4>
              <h5>客服很有耐心，服务的态度也很好，</h5>
              <span>{{ computedTime(75.1) }} 周口</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/005NWMakgy1go5d9zj33lj30b40b4wex.jpg"
              alt=""
            />
            <div>
              <h4>124****7485</h4>
              <h5>
                客服很耐心 一下一下教虽然我有点不会可能🤔 省去了很多麻烦
                以后再也不用路灯斑马线了
              </h5>
              <span>{{ computedTime(77.2) }} 上海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/005uco5xly8glucbfz0bpj30u00u0ab3.jpg"
              alt=""
            />
            <div>
              <h4>无**潇</h4>
              <h5>全自助服务的，点赞</h5>
              <span>{{ computedTime(79) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/6f0b042755720099c2faf0ef35597c9e.jpg"
              alt=""
            />
            <div>
              <h4>t**1</h4>
              <h5>
                买的定制版，很方便，客服也很耐心的解答问题。不错，省了不少事。
              </h5>
              <span>{{ computedTime(80.2) }} 佛山</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 4">
          <li>
            <img
              src="@/assets/img2/6f446850066332f462957a878e7a7d448977.jpeg"
              alt=""
            />
            <div>
              <h4>尼**帆</h4>
              <h5>东西很好，很方便，而且客服很耐心，操作很简单，帮了大忙了</h5>
              <span> {{ computedTime(82.5) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/006qkfXVly1g3g8797epqj318g18g78e.jpg"
              alt=""
            />
            <div>
              <h4>发**啊</h4>
              <h5>
                很好，很快，省过红绿灯的时候啦！建议大家放心付款购买。。。
              </h5>
              <span>{{ computedTime(91) }} 武汉</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/007RM2dVgy1hsnfcu5rvlj30j60j6jsb.jpg"
              alt=""
            />
            <div>
              <h4>2**n</h4>
              <h5>搞定了 非常迅速 流程方便 解答耐心 体验很流畅 很安全很nice</h5>
              <span>{{ computedTime(95.2) }} 上海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/008auzvogy1hsk13jce1wj30u00tldip.jpg"
              alt=""
            />
            <div>
              <h4>低**5</h4>
              <h5>
                真的很不错，秒发货，客服态度也特别好，收嘎嘎新的号值得购买
              </h5>
              <span>{{ computedTime(105) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/34fae6cd7b899e51afeb39004da7d933c8950db8.jpg"
              alt=""
            />
            <div>
              <h4>t**4</h4>
              <h5>给对象买的号，重新绑过邮箱和密码了，确实方便！</h5>
              <span>{{ computedTime(106.2) }} 珠海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/0066V6b3ly8gm4crdej41j30ro0rodgb.jpg"
              alt=""
            />
            <div>
              <h4>我**长</h4>
              <h5>登录成功！冲冲冲。</h5>
              <span>{{ computedTime(107) }} 开封</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/80b6db455a131626e51893d3e2bb08bc.jpeg"
              alt=""
            />
            <div>
              <h4>t**4</h4>
              <h5>
                很好客服态度也很好 一次愉快的购买体验 谢谢客服帮我换绑 谢谢啦。
              </h5>
              <span>{{ computedTime(109.2) }} 开封</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/908fa0ec08fa513d69308da8336d55fbb2fbd92a.jpg"
              alt=""
            />
            <div>
              <h4>152****7931</h4>
              <h5>抱着试试的心态购买，没想到这么靠谱，好评。</h5>
              <span>{{ computedTime(110.1) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/4500ed5a555414fcc38b5f63cb23124a1135.jpeg"
              alt=""
            />
            <div>
              <h4>族***爱</h4>
              <h5>
                客服态度很好，第一次弄有点弄不明白，问客服问题都耐心解答，账号也没有问题，放心冲吧
              </h5>
              <span>{{ computedTime(116.2) }} 沈阳</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/94905ad84fb925eb37fa7eec69f79cd242454782.jpg"
              alt=""
            />
            <div>
              <h4>3**z</h4>
              <h5>客服很用心的解答遇到的问题，登录速度很快，好评</h5>
              <span>{{ computedTime(118) }} 石家庄</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 5">
          <li>
            <img src="@/assets/img2/53815198fe.jpg" alt="" />
            <div>
              <h4>151****6871</h4>
              <h5>账号登录过程很快，商家服务态度也很好</h5>
              <span> {{ computedTime(121) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/img2/20171120222900_4ABT2.jpeg" alt="" />
            <div>
              <h4>北**3</h4>
              <h5>
                登录的很快，简单方便易懂，性价比高，省去了很多时间，值得推荐
              </h5>
              <span>{{ computedTime(125.3) }} 武汉</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/img2/20190215201614_z3A4y.jpeg" alt="" />
            <div>
              <h4>t**0</h4>
              <h5>
                发货很快！按照步骤很简单就注册成功了。真的好用比自己验证强太多了。还不贵
              </h5>
              <span>{{ computedTime(126) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/img2/下载 (1).jpg" alt="" />
            <div>
              <h4>低**啊</h4>
              <h5>发货非常快，客服非常有耐心，账号登录的方式很方便，好用</h5>
              <span>{{ computedTime(127.3) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img src="@/assets/img2/下载.jpg" alt="" />
            <div>
              <h4>须**m</h4>
              <h5>产品太好了 登陆的速度特别快十分方便 也不贵 客服回复也快</h5>
              <span>{{ computedTime(132) }} 珠海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/ad63f1d7f73f4d598f8a852256489a00.jpeg"
              alt=""
            />
            <div>
              <h4>H**p</h4>
              <h5>登录成功！冲冲冲。</h5>
              <span>{{ computedTime(135.2) }} 开封</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/c57b9a1b3510eefcb3f9de43dcdca5d8.jpg"
              alt=""
            />
            <div>
              <h4>嚄**跟</h4>
              <h5>很快 客服很耐心 一会就搞定了 下次还来</h5>
              <span>{{ computedTime(136) }} 开封</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/c743d2066db93d7e4bb72c24c07c104d.jpg"
              alt=""
            />
            <div>
              <h4>152****5121</h4>
              <h5>确实快啊，终于可以上了</h5>
              <span>{{ computedTime(138.3) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/d062a62ff23754f83e96ca66c2e43c4badf43a66.jpg"
              alt=""
            />
            <div>
              <h4>族***爱</h4>
              <h5>卖家回复超快！必须五星好评</h5>
              <span>{{ computedTime(150.3) }} 沈阳</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/dc8a9d71f31648f485585582093c4155.jpeg"
              alt=""
            />
            <div>
              <h4>8**z</h4>
              <h5>不错不错，成功了，挺好的购物体验</h5>
              <span>{{ computedTime(171) }} 石家庄</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 6">
          <li>
            <img
              src="@/assets/img2/e6449d8d549635b06eff51a80bd9752a.jpg"
              alt=""
            />
            <div>
              <h4>181****9871</h4>
              <h5>
                自己注册了好久都没成功 商家几分钟就搞定了速度很快
                不懂得问客服回复速度也快还耐心解答
              </h5>
              <span> {{ computedTime(175) }} 北京</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/fe06f3c6bc09d93612058f8cae40f8c0.jpg"
              alt=""
            />
            <div>
              <h4>北**3</h4>
              <h5>
                买的定制版 登录很快 效率高 值得推荐 下次还来 有喜欢的可以来这买
              </h5>
              <span>{{ computedTime(179.2) }} 武汉</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/ff0b6d250839a43eadab5e759dc4338c.jpg"
              alt=""
            />
            <div>
              <h4>d**气</h4>
              <h5>非常快就登录成功了🏆</h5>
              <span>{{ computedTime(181) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___b-ssl.duitang.com_uploads_item_201811_06_20181106210452_kjljj.jpeg&refer=http___b-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>t**9</h4>
              <h5>
                我啥也不会，客服特别有耐心一直在指导，终于弄好了，非常好的一次购物体验，会推荐朋友也来买
              </h5>
              <span>{{ computedTime(185.3) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___b-ssl.duitang.com_uploads_item_201905_17_20190517024755_Sr8et.jpeg&refer=http___b-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>方**印</h4>
              <h5>下次还会来的</h5>
              <span>{{ computedTime(194.3) }} 珠海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_blog_202008_28_20200828162706_9444b.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>H**p</h4>
              <h5>会给朋友们推荐。好评。</h5>
              <span>{{ computedTime(195.2) }} 开封</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_blog_202008_30_20200830232524_bdb37.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>嚄**额</h4>
              <h5>账号登录特别快 客服也很耐心提供教程，推荐</h5>
              <span>{{ computedTime(200) }} 开封</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_blog_202106_17_20210617083248_65683.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>x**9</h4>
              <h5>确实快啊，终于可以上了</h5>
              <span>{{ computedTime(201.9) }} 广州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_blog_202107_11_20210711114259_72126.thumb.1000_0.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>赔***饭</h4>
              <h5>安全、放心</h5>
              <span>{{ computedTime(206) }} 沈阳</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201411_12_20141112180532_8rGR3.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>5**z</h4>
              <h5>不错不错，成功了，挺好的购物体验</h5>
              <span>{{ computedTime(207.9) }} 石家庄</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 7">
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201505_19_20150519124956_dLRPi.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>呵**者</h4>
              <h5>商品很好，很值得信赖，欢迎大家来购买</h5>
              <span> {{ computedTime(208.2) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201508_11_20150811151647_VfNEK.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>j**y</h4>
              <h5>很好用！收到之后立马就能登上了 非常好使</h5>
              <span>{{ computedTime(211) }} 宁波</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201511_20_20151120000855_YzJEs.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>t**7</h4>
              <h5>发货很快，效率非常好，很良心，推荐推荐</h5>
              <span>{{ computedTime(214.2) }} 上海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201809_06_20180906213926_ff2zy.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>t**9</h4>
              <h5>操作简单，客服态度很好，推荐购买</h5>
              <span>{{ computedTime(219.1) }} 福州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201902_02_20190202231053_CFFVK.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>须**Y</h4>
              <h5>商家诚实东西非常好，操作方便，下单立马发货</h5>
              <span>{{ computedTime(222) }} 珠海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_201903_13_20190313145756_rc4LG.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>遇**美</h4>
              <h5>登录成功！速度很快。</h5>
              <span>{{ computedTime(223.5) }} 青岛</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202002_02_20200202143027_N3S2P.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>嚄**跟</h4>
              <h5>很快 客服很耐心 一会就搞定了 下次还来</h5>
              <span>{{ computedTime(229) }} 开封</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202001_03_20200103132803_ruxfj.thumb.400_0.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>152****8851</h4>
              <h5>客服服务很好。发货也很快。 我很满意。</h5>
              <span>{{ computedTime(234) }} 厦门</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202002_02_20200202143027_N3S2P.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>t**很</h4>
              <h5>
                自己注册半小时没注册过，买了定制版的
                几分钟就搞定了，速度非常快！！！感谢老板
              </h5>
              <span>{{ computedTime(236.5) }} 沈阳</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202003_10_20200310104813_vewxd.thumb.1000_0.jpg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>5**T</h4>
              <h5>回复快，而且能进入</h5>
              <span>{{ computedTime(240.5) }} 长沙</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
        <template v-if="observerIndex > 8">
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202003_24_20200324163117_QmJvZ.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>158****6071</h4>
              <h5>
                不错很快就弄好了 从下单到账号注册好也就两分钟 操作简单快速
              </h5>
              <span> {{ computedTime(250.3) }} 北京</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202004_01_20200401205104_5V2RF.thumb.1000_0.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>北**5</h4>
              <h5>好好好好好,发货很快。钱到号到、、</h5>
              <span>{{ computedTime(261) }} 惠州</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202004_04_20200404232342_2rkdf.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>GSF**8</h4>
              <h5>
                非常快速，5分钟就能注册好账号，还可以用的自己的邮箱，非常放心
              </h5>
              <span>{{ computedTime(263.5) }} 上海</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202004_04_20200404232345_HBwVa.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>伟**啊</h4>
              <h5>商家服务到位，比如马服强的太多太多了。</h5>
              <span>{{ computedTime(265.1) }} 西安</span><strong>推特(解敏号)</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202004_19_20200419004404_Ny5jj.thumb.1000_0.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>我**啊</h4>
              <h5>
                登录成功啦～按照教程里面的改密码什么的很方便，这家店铺会
                安利给朋友的～
              </h5>
              <span>{{ computedTime(270) }} 珠海</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202004_19_20200419214507_wjhya.thumb.1000_0.jpg&refer=http___c-ssl.duitang (1).webp"
              alt=""
            />
            <div>
              <h4>H**p</h4>
              <h5>登录成功！冲冲冲。</h5>
              <span>{{ computedTime(272.9) }} 武汉</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___ci.xiaohongshu.com_c783bd63-746b-5896-806f-68bcf9b9cb99_imageView2_2_w_1080_format_jpg&refer=http___ci.xiaohongshu (1).webp"
              alt=""
            />
            <div>
              <h4>阿**啊</h4>
              <h5>速度非常快就注册好了 操作步骤很详细 good！</h5>
              <span>{{ computedTime(278) }} 开封</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___c-ssl.duitang.com_uploads_item_202010_05_20201005174057_zh43r.thumb.1000_0.jpeg&refer=http___c-ssl.duitang.webp"
              alt=""
            />
            <div>
              <h4>152****5541</h4>
              <h5>确实快啊，终于可以上了</h5>
              <span>{{ computedTime(279.2) }} 哈尔滨</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___ci.xiaohongshu.com_887668b9-9fdd-52ec-a28f-9fc76fadb59a_imageView2_2_w_1080_format_jpg&refer=http___ci.xiaohongshu.webp"
              alt=""
            />
            <div>
              <h4>族***流</h4>
              <h5>发货很快，很方便，，点赞</h5>
              <span>{{ computedTime(290) }} 沈阳</span><strong>推特新号</strong>
            </div>
          </li>
          <li>
            <img
              src="@/assets/img2/src=http___ci.xiaohongshu.com_a8de3b57-3365-5d7d-a4ed-42c8a818ae41_imageView2_2_w_1080_format_jpg&refer=http___ci.xiaohongshu.webp"
              alt=""
            />
            <div>
              <h4>8**z</h4>
              <h5>不错不错，登录成功了，挺好的购物体验</h5>
              <span>{{ computedTime(294.5) }} 宁夏</span><strong>推特新号</strong>
            </div>
          </li>
        </template>
      </ul>
      <div v-show="isLoading" style="width: 100%; text-align: center">
        <i class="el-icon-loading"></i>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "ProductComment",
  props: {
    commentDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observerIndex: 1,
      isLoading: false,
      items: [],
    };
  },
  mounted() {
    // 添加滚动事件监听器
    this.$nextTick(() => {
      console.log("scrollContainer", this.$refs["scrollContainer"]);
      this.$refs["scrollContainer"].addEventListener(
        "scroll",
        this.handleScroll
      );
    });
  },
  methods: {
    // 计算时间
    computedTime(num) {
      const now = dayjs();
      // 计算当前时间减去 8 个小时
      const eightHoursAgo = now.subtract(num, "hour");
      return eightHoursAgo.format("YYYY-MM-DD HH:mm:ss");
    },
    handleClose() {
      this.$emit("update:commentDrawer", false);
    },
    handleScroll() {
      console.log("我被调用了");
      const container = this.$refs["scrollContainer"];
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      // 检查是否滚动到容器底部
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        console.log("到底了");
        this.loadMore(); // 触发加载更多内容的函数
      }
    },
    loadMore() {
      if (this.isLoading) return; // 防止重复加载
      this.isLoading = true;
      if (this.observerIndex > 10) {
        this.$refs["scrollContainer"].removeEventListener(
          "scroll",
          this.handleScroll
        );
        return;
      }
      // 模拟异步加载
      setTimeout(() => {
        // 添加更多内容
        this.observerIndex++;
        // const newItems = Array.from({ length: 20 }, (_, i) => ({
        //   id: Date.now() + i,
        //   text: `Item ${this.items.length + i + 1}`,
        // }));
        // this.items.push(...newItems);
        this.isLoading = false;
      }, 1800);
    },
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    this.$refs["scrollContainer"].removeEventListener(
      "scroll",
      this.handleScroll
    );
  },
};
</script>

<style scoped lang="scss">
.observer {
  height: 92%;
  width: 100%;
  padding-left: 5px;
  overflow-y: auto;
  li {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #eedfdf9f;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding: 5 10px;
    width: 100%;
    padding-bottom: 3px;
    padding-right: 18px;
    div {
      width: 100%;
    }
    img {
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 20px;
      margin-right: 15px;
    }
    h4 {
      font-weight: 700;
      font-size: 12px;
    }
    h5 {
      margin: 5px 0;
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: #cccccc;
      margin-right: 15px;
    }
    strong {
      font-size: 12px;
      float: right;
      color: #d6d0d0;
    }
  }
}
</style>
