<template>
  <div class="header">
    <div class="header-wrap m-wrap">
      <div>
        <img
          class="header_msg"
          src="@/assets/img/pc-logo.png"
          alt=""
        />
        <!-- <span class="log_text"> 推特账号专卖</span> -->
      </div>
      <div class="search">
        <!-- <input type="text" placeholder="请输入商品单号" /> -->
        <el-button
          size="small"
          @click="handleOrderInquiry"
          icon="el-icon-search"
          type="primary"
          >订单查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleOrderInquiry() {
      if (this.$route.name === "OrderSearch") return;
      // this.$router.push("/OrderSearch");
      this.$router.push({ name: "OrderSearch", query: { order_id: "" } });
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 50px;
  background-color: pink;
  font-size: 14px;
  box-shadow: 2px 0 10px 1px #b3b3b375;
  height: 75px;
  line-height: 75px;
  background: #fff;
  .header-wrap {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    max-width: 1300px;
    padding: 0 16px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .header_msg {
    height: 60px;
    vertical-align: middle;
    cursor: pointer;
  }
  .log_text {
    font-size: 22px !important;
    // background: linear-gradient(90deg, #ff6f00, #ff3d00); /* 渐变背景 */
 /* 字体大小 */
    font-weight: bold; /* 字体加粗 */
    background: linear-gradient(90deg, #ff6f00, #ff3d00); /* 渐变背景 */
    -webkit-background-clip: text; /* Webkit 浏览器（Chrome, Safari） */
    background-clip: text; /* 其他现代浏览器 */
    color: transparent; /* 确保文字颜色透明 */
  }
}
</style>
