<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import Home from "./components/Home.vue";

export default {
  name: "App",
  // components: {
  //   Home,
  // },
};
</script>

<style>
@media screen and (max-width: 600px) {
  body {
    /* background-color: pink; */
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  /* 可以根据需要添加其他样式 */
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0;
}
</style>
